import { ErrorPage } from '@hkexpressairwayslimited/ui';
import { useTransContent } from 'lib/transContent';
import Head from 'next/head';
import { useRouter } from 'next/router';

/**
 * Rendered in case if we have 404 error
 */
const NotFound = () => {
  const { t, tPlain } = useTransContent();
  const router = useRouter();

  function BackToHomePage() {
    router.push(`/`);
  }

  return (
    <>
      <Head>
        <title>404: NotFound</title>
      </Head>
      <ErrorPage
        title={t('web.page.not.found.title')}
        text={t('web.page.not.found.text')}
        desc={tPlain('web.page.not.found.desc')}
        onCountdownEnd={BackToHomePage}
      />
    </>
  );
};

export default NotFound;
